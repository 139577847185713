import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import ScrollspyNav from 'react-scrollspy-nav';

import '../../generic-page.scss';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const NikkeGuidesTeamPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Team building</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_8.png"
            alt="Team building"
          />
        </div>
        <div className="page-details">
          <h1>Team building</h1>
          <h2>Learn the optimal ways to build your team in NIKKE.</h2>
        </div>
      </div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Burst Types" />
              <h5>Burst Meter</h5>
              <p>
                The most important element that will affect how you build your
                teams in NIKKE is the{' '}
                <strong>Burst Type of your characters</strong>.
              </p>
              <p>
                As you are killing enemies in the battle, the{' '}
                <strong>
                  Burst Meter slowly builds up and once it fills fully
                </strong>
                , you are able to use the most powerful ability your characters
                have - Burst Skill. But there's a catch. The Burst Meter is
                split into 'phases' - I, II, and III - and during each phase,{' '}
                <strong>
                  only the characters that have matching Burst Type, can use
                  their skill
                </strong>
                .
              </p>
              <p>
                What's worse, if you don't use a skill of a matching type during
                the Burst phase, it won't progress to the next one. This forces
                you to build a team that{' '}
                <strong>has all three Burst Types</strong>, because this is the
                only way to reach the 4th stage (called Full Burst) that starts
                after you use a skill during the 3rd phase.
              </p>
              <p>
                Furthermore, after entering a Burst Phase, you only have 10
                seconds to use a skill. If you won't use it, you will have to
                build the Burst Meter from the scratch again.
              </p>
              <h5>Burst Types</h5>
              <p>
                Generally, characters with Burst I type are supporters or
                healers and Burst II and III fulfill the role of damage dealers.
              </p>
              <p>
                Also, the cooldown for the Burst Skill is what you need to
                always check - some characters have long cooldown (40 seconds)
                and other have much shorter one (only 20 seconds). For Burst I
                and II characters having 20 second cooldown is really important
                as it allows you to enter the Full Burst mode more often.
              </p>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Elements" />
              <p>
                Each character has one of the 5 elements available in the game,
                but compared to the Burst Type or Weapons, they are not that
                important. Mainly because elemental advantage only gives 10%
                increased damage.
              </p>
              <p>
                Here is the list of elements in the game and how they work with
                each other:
              </p>
              <StaticImage
                src="../../../images/nikke/generic/elemental_system.jpg"
                alt="Elemental"
              />
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Formations" />
              <p>
                You can bring 5 characters into the battle, and if you're using
                the Auto-Skill feature, you have to make sure you set your team
                right.
              </p>
              <p>
                Let's look at the formation below to better understand how the
                Auto-Skill works in NIKKE.
              </p>
              <StaticImage
                src="../../../images/nikke/generic/team_formation.png"
                alt="Team formation"
              />
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297016"
              ></div>
              <p>
                Using the Auto-Skill mode means that AI will{' '}
                <strong>scan your formation from left to right</strong>,
                searching for Burst I characters first - as they are the ones
                that start the burst.{' '}
                <strong>
                  If you have no Burst I characters in your team, Auto-skill
                  won't do anything
                </strong>
                . This forces you to have at least one character of each Burst
                Type in your team or else you will never reach Full Burst mode
                (that activates after using Burst III skill).
              </p>
              <p>
                In the team above, since Product O8 is the first Burst I
                character when scanning from left to right, her Burst will be
                used. Next, again scanning from left to right, the AI will
                search for the first Burst II character - in our case it's Anis
                - and use her burst skill.
              </p>
              <p>
                The last step is to scan for Burst III characters and Rapi skill
                will be used by the AI.
              </p>
              <p>
                <strong>
                  Important! If a character's skill is on cooldown, she's dead
                  or stunned, she will be skipped and the next character of the
                  same Burst Type will use her skill instead.
                </strong>
              </p>
              <p>
                Also, keep in mind that some characters have longer cooldowns on
                their Burst Skill, and it's also an important factor when
                building the team - if you only use characters with 40s
                cooldown, you will have to wait for a long time to enter Full
                Burst.
              </p>
              <p>
                This gives you limited freedom with how you can build the team.
              </p>
              <h5>2-1-2</h5>
              <p>2x Burst I, 1x Burst II, 2x Burst III</p>
              <p>
                This is a balanced formation that allows you to weave the skills
                and enter the Full Burst phase as often as possible, but it's
                really important for the Burst II character to have 20 second
                cooldown on her Burst Skill.
              </p>
              <p>
                The 2-1-2 formation is also the best choice if you lack top tier
                characters, so it will be great at the start of the game where
                you will have to use what RNG gave you.
              </p>
              <p>
                For specialized teams that want to focus on one weapon type and
                make it as strong as possible, this formation is also the best
                one as it gives you more support characters that are required
                for specialized compositions to work.
              </p>
              <h5>1-1-3</h5>
              <p>1x Burst I, 1x Burst II, 3x Burst III</p>
              <p>
                This formation exchanges flexibility for tons of firepower, but
                to make it work you will need some of the best Burst I and Burst
                II characters in the game or else your team will lack healing
                and supportive skills and it will be very hard to survive in
                harder stages.
              </p>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Example teams" />
              <p>Check our early and meta team guide for some example teams.</p>
              <Row
                xs={1}
                md={2}
                lg={2}
                xxl={3}
                className="g-3 category-cards-container"
              >
                <CategoryCard
                  title="Early and late meta teams"
                  link="/nikke/guides/meta-teams"
                  image={
                    <StaticImage
                      src="../../../images/nikke/categories/category_beginner.png"
                      alt="Early and late meta teams"
                    />
                  }
                />
              </Row>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Burst Types</a>
                </li>
                <li>
                  <a href="#section-1">Elements</a>
                </li>
                <li>
                  <a href="#section-2">Formations</a>
                </li>
                <li>
                  <a href="#section-3">Example teams</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesTeamPage;

export const Head: React.FC = () => (
  <Seo
    title="Team building | NIKKE | Prydwen Institute"
    description="Learn the optimal ways to build your team in NIKKE"
  />
);
